import { Component, OnInit } from '@angular/core';
import {SimpleSmoothScrollService} from 'ng2-simple-smooth-scroll';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  toggle = false;
  constructor(private smooth: SimpleSmoothScrollService) {
  }
  ngOnInit() {
    //this.smooth.smoothScrollToAnchor();
  }
  goTop() {
    this.smooth.smoothScrollToTop({duration: 100, easing: 'easeOutQuad'});
  }

}


