import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, map, publishLast, refCount} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  api = environment.apiUrl;
  constructor( private http: HttpClient) { }

  sendMail(params): Observable<any[]> {
    const url = `${this.api}/mail.php`;
    return this.http.post<any>(url,
        {
          replyTo: params.replyTo,
          subject: params.subject,
          html: params.html
        }).pipe(
        map(result => {
          console.log('result');
          return  result;
        }),
        catchError(error => throwError( error )),
        publishLast(),
        refCount()
    );
  }
}
