import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../service/app.service';
import {whileComponentNotDestroyed} from '../../shared/component-destroy-observer';


@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
    map: any;
    userForm: FormGroup;
    success = false;

    constructor(private appService: AppService) {
    }

    ngOnInit() {
        ymaps.ready(() => {
            this.map = new ymaps.Map('map', {
                center: [51.761230, 36.197060],
                zoom: 11
            });
            this.map.behaviors.disable('scrollZoom');
            const placeMark = new ymaps.Placemark([51.761230, 36.197060], {
                    balloonContentHeader: 'AXSOFT',
                    balloonContentBody: '+7 (4712) 238-250',
                    balloonContentFooter: 'ул. Хуторская, дом 16 Г, офис 13',
                    hintContent: 'Разработка программного обеспечения'
                },
                {
                    preset: 'islands#circleDotIcon',
                    iconColor: '#ff0000'
                });
            this.map.geoObjects.add(placeMark);
        });

        this.initForm();
    }

    initForm() {
        this.userForm = new FormGroup({
            firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
            lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
            email: new FormControl('', [Validators.email, Validators.required]),
            subject: new FormControl('', [Validators.required, Validators.minLength(1)]),
            message: new FormControl('', [Validators.required, Validators.maxLength(150)]),
            captcha: new FormControl( '', Validators.required)
        });
    }

    /* onSubmit() {
        if (this.userForm.valid) {
            const params = {
                replyTo: 'axsoftru@yandex.ru',
                subject: 'Новая заявка с сайта axsoft.ru',
                html: `<p>Заявка отправлена с сайта&nbsp;
<strong><span><a href="http://axsoft.ru" target="_blank">axsoft.ru</a>
</span></strong></p>
<p><strong><em><span>Контактные данные пользователя:</span></em></strong></p>
<p><strong><span >Имя: ${this.userForm.value.firstName}</span></strong></p>
<p><strong><span>Фамилия: ${this.userForm.value.lastName}</span></strong></p>
<p><strong><span>Регион: ${this.userForm.value.subject}</span></strong></p>
<p><strong><span>Email: <a href="http://mailto:${this.userForm.value.email}">${this.userForm.value.email}</a>&nbsp;</span></strong></p>
<p><strong><span>Текст сообщения: ${this.userForm.value.message}&nbsp;</span></strong></p>`
            };
            this.appService.sendMail(params)
                .pipe(whileComponentNotDestroyed(this))
                .subscribe(
                    result => {
                        console.log(result);
                        this.success = true;
                        alert('Сообщение отправлено');
                        setTimeout(() => {
                            this.success = false;
                            this.userForm.reset();
                        }, 3000);
                    },
                    (error) => {
                        console.log(error);
                        alert('Сообщение отправлено');
                    });
        }
    }
    resolved(captchaResponse: string) {
        this.userForm.controls.captcha.setValue(captchaResponse);
    }*/
}
