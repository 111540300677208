import {Component, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
    images = [
        {id: 1, name: 'отзыв 1', url: 'assets/image/partners/Galaktika2.jpg'},
        {id: 2, name: 'отзыв 2', url: 'assets/image/partners/Usetech.jpg'},
        {id: 3, name: 'отзыв 3', url: 'assets/image/partners/ficom.jpg'},
        {id: 4, name: 'отзыв 4', url: 'assets/image/partners/voentel.jpg'},
        {id: 5, name: 'отзыв 5', url: 'assets/image/partners/Galaktika.jpg'}
    ];
    slides = [
        {
          img: "assets/image/partners/ficom.webp",
          link: 'https://www.ficom-it.ru/'
        },
        {
          img: "assets/image/partners/galaktika.webp",
          link: 'https://galaktika.ru/'
        },
        {
          img: "assets/image/partners/wartelecom.webp",
          link: 'https://voentelecom.ru/'
        },
        {
          img: "assets/image/partners/usetech.webp",
          link: 'https://usetech.com/'
        },
      {
        img: "assets/image/partners/tizer.jpg",
        link: 'https://tizerauto.ru/'
      },

    ];
    slideConfig = {
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "prevArrow": '<button type="button" class="arrow-slide icon-angle-left"></button>',
        "nextArrow": '<button type="button" class="arrow-slide icon-angle-right"></button>',
        responsive: [
          {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };

    slickInit(e) {
        console.log('slick initialized');
    }

    breakpoint(e) {
        console.log('breakpoint');
    }

    afterChange(e) {
        console.log('afterChange');
    }

    beforeChange(e) {
        console.log('beforeChange');
    }

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    open(content) {
        this.modalService.open(content, {size: 'xl'});
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
