import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competencies',
  templateUrl: './competencies.component.html',
  styleUrls: ['./competencies.component.scss']
})
export class CompetenciesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
