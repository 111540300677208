import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {SimpleSmoothScrollModule} from 'ng2-simple-smooth-scroll';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbAccordionModule, NgbCarouselModule, NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {MainComponent} from './main/main.component';
import {CompetenciesComponent} from './main/competencies/competencies.component';
import {TechnologyComponent} from './main/technology/technology.component';
import {WorkStagesComponent} from './main/work-stages/work-stages.component';
import {ContactsComponent} from './main/contacts/contacts.component';
import {FooterComponent} from './main/footer/footer.component';
import {ProjectsComponent} from './main/projects/projects.component';
import {HeaderComponent} from './main/header/header.component';
import {AboutUsComponent} from './main/about-us/about-us.component';
import {PartnersComponent} from './main/partners/partners.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import { VacancyComponent } from './vacancy/vacancy.component';
import {RouterModule, Routes} from '@angular/router';
import { MenuComponent } from './main/menu/menu.component';

const appRoutes: Routes =[
  { path: '', component: MainComponent},
  { path: 'vacancy', component: VacancyComponent},
  { path: '**', component: AppComponent }
];

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        CompetenciesComponent,
        TechnologyComponent,
        WorkStagesComponent,
        ContactsComponent,
        FooterComponent,
        ProjectsComponent,
        HeaderComponent,
        AboutUsComponent,
        PartnersComponent,
        VacancyComponent,
        MenuComponent
    ],
  imports: [
    BrowserModule,
    NgbModule,
    NgbAccordionModule,
    SimpleSmoothScrollModule,
    NgbModalModule,
    NgbCarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    SlickCarouselModule,
    RouterModule.forRoot(appRoutes)
  ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
